/* eslint-disable react/no-unknown-property */
import React from 'react'
import { Helmet } from 'react-helmet'
// import { Link } from 'gatsby'

/* SCSS */
import './styles/privacidade.scss'

/* COMPONENTS */
import Layout from '../components/Layout/index'

/* SVG */
import carrinho from '../images/privacidade/carrinho.svg'
import hamburguer from '../images/privacidade/hamburguer.svg'
import blackLine1 from '../images/privacidade/black-line-1.svg'
import lampada from '../images/privacidade/lampada.svg'
import computador from '../images/privacidade/computador.svg'
import hamburguer2 from '../images/privacidade/hamburguer-2.svg'
import dado from '../images/privacidade/dado.svg'
import blackLine2 from '../images/privacidade/black-line-2.svg'
import centro from '../images/privacidade/centro.svg'
import biscoito from '../images/privacidade/biscoito.svg'
import computador2 from '../images/privacidade/computador-2.svg'
import mundo from '../images/privacidade/mundo.svg'
import hotdog from '../images/privacidade/hotdog.svg'
import ampulheta from '../images/privacidade/ampulheta.svg'
import blackLine3 from '../images/privacidade/black-line-3.svg'
import nuvem from '../images/privacidade/nuvem.svg'
import internet from '../images/privacidade/internet.svg'
import cadeado from '../images/privacidade/cadeado.svg'
import hamburguer3 from '../images/privacidade/hamburguer-3.svg'
import passaro from '../images/privacidade/passaro.svg'
import heroi from '../images/privacidade/heroi.svg'
import pessoa from '../images/privacidade/pessoa.svg'

import blackLineMobile1 from '../images/privacidade/mobile/black-line-1.svg'
import blackLineMobile2 from '../images/privacidade/mobile/black-line-2.svg'

import blackLine1DM from '../images/privacidade/black-line-1-dm.svg'
import blackLine3DM from '../images/privacidade/black-line-3-dm.svg'

const Banner = () => (
  <>
    <section className="container-fluid p-0 d-md-block d-none">
      <div className="backgroud-banner-diaxpress">
        <div className="container d-flex justify-content-start">
          <div className="banner-info">
          </div>
        </div>
      </div>
    </section>
    <section className="container-fluid p-0 d-md-none d-block">
      <div className="backgroud-banner w-100"></div>
      <div className="container d-flex justify-content-center">
        <div className="banner-info  d-flex flex-column align-items-center m-0 p-0">
        </div>
      </div>
    </section>
  </>
)

const Privacidade = () => {
  const breadC = {
    visible: true,
    color: 'black',
    items: [
      { path: '/', name: 'Home' },
      { path: '/privacidade/', name: 'Privacidade' }
    ]
  }

  return (
    <Layout breadCrumb={breadC}>
      <Helmet>
        <html lang="pt-BR" amp />
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Política de Privacidade | DIA Supermercados</title>
        <meta name="description" content="Estes Termos e Condições de Uso Termos regulam a utilização do aplicativo Dia Express e qualquer outro aplicativo, portal, plataforma site e etc." />
        <meta name="keywords" content="politica de privacidade" />
        <meta name="author" content="DIA Supermercados" />
      </Helmet>
      <Banner />
      <section className='bg-water-blue-2'>
        <div className='container'>
          <div className="row">
            <div className='col-12'>
              <div className='d-flex justify-content-center pb-5 my-5'>
                <div className='bg-red-1 pt-4 pb-5 px-4'>
                  <div className='row text-white justify-content-center'>
                    <h3 className='text-center mt-2 mb-3'>AVISO DE PRIVACIDADE E PROTEÇÃO DE DADOS</h3>
                    <div className='col-lg-8'>
                      <p className='mb-3'>Data de atualização: 28.3.2023</p>
                      <p className='mb-3'>DIA Brasil Sociedade LTDA<br />
                        Endereço: Avenida Ibirapuera, 2332 - Indianópolis - São Paulo - SP -<br />
                        CEP 04028-002.<br />
                        CNPJ: 03.476.811/0001-51
                      </p>
                      <p>Aqui no DIA a gente pensa em tudo, inclusive na proteção dos seus dados pessoais.<br />
                        Por isso, para garantir que você tenha informação clara sobre como tratamos e protegemos os seus dados, elaboramos este AVISO DE PRIVACIDADE E PROTEÇÃO DE DADOS. Bora lá?</p>
                    </div>
                    <div className='d-flex justify-content-center mt-lg-0 mt-4 col-lg-4'>
                      <img src={carrinho} className='w-100' />
                    </div>
                  </div>
                </div>
              </div>
              <div className='line d-lg-flex d-none'></div>
              <div className='d-flex justify-content-center'>
                <div className='col-lg-2 position-relative'>
                  <img src={hamburguer} className='position-absolute hamburguer z-index-1' />
                </div>
                <div className='col-lg-8 col-12 d-flex justify-content-center align-items-center position-relative'>
                  <img src={blackLine1} className='w-90 position-absolute blackLine1 lightMode' />
                  <img src={blackLine1DM} className='w-90 position-absolute blackLine1 darkMode' />
                  <img src={blackLineMobile1} className='w-100 d-md-none position-absolute blackLine1' />
                  <div className='text-center mx-lg-4 py-lg-0 py-5 px-lg-5 px-3 z-index-1'>
                    <p className='font-weight-bold mb-3'>1. O que é este aviso?</p>
                    <p>Você já ouviu falar na Lei Geral de Proteção de Dados – LGPD? A LGPD é a lei que
                      veio para dispor sobre como os dados pessoais devem ser tratados, impondo
                      algumas obrigações para quem trata dados pessoais. Este aviso serve, por exemplo,
                      para informar você quais os dados e os motivos pelo qual o DIA coleta esses dados
                      pessoais, o que faz com esses dados e como os protege.
                    </p>
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-center pt-5 mt-5'>
                <div className='col-auto position-relative'>
                  <img src={lampada} className='position-absolute lampada z-index-1' />
                </div>
                <div className='col-lg-4 col-12'>
                  <div className='bg-red-2 px-5 py-4 text-center text-white'>
                    <p className='font-weight-bold mb-3'>2. Para ficar mais claro....</p>
                    <p>é importante que você saiba o significado de alguns termos:</p>
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-center mt-3 mx-lg-4'>
                <div className='col-lg-10 col-12'>
                  <div className='bg-dark-water-blue px-lg-5 px-3 py-4'>
                    <ul>
                      <li><b>Dado Pessoal:</b> é aquela informação que permite a sua identificação. Por exemplo, seu nome, seu RG, seu endereço, entre vários outros dados.</li>
                      <li><b>Dado Pessoal Sensível:</b> são dados que revelam, por exemplo, a sua origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico.</li>
                      <li><b>Controlador:</b> é quem decide como os seus dados pessoais serão tratados. Neste caso, o DIA é o controlador dos seus dados.</li>
                      <li><b>Tratamento:</b> tudo o que é feito com os seus dados pessoais - por exemplo, é a coleta, o armazenamento, o compartilhamento, etc.</li>
                      <li><b>Titular:</b> significa você, nosso cliente, que é o dono do seu dado pessoal. Tudo certo? Agora vamos ao que importa!</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-center pt-4 mt-5'>
                <div className='col-auto position-relative'>
                  <img src={computador} className='position-absolute computador z-index-1' />
                </div>
                <div className='col-lg-4 col-12'>
                  <div className='bg-red-3 text-center text-white'>
                    <p className='font-weight-bold'>3. Como o DIA tem acesso aos seus dados? </p>
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-center mt-3 mx-lg-4'>
                <div className='col-lg-10 col-12 position-relative'>
                  <img src={hamburguer2} className='position-absolute hamburguer2 z-index-1' />
                  <div className='bg-light-water-blue px-lg-5 px-3 py-lg-4 pt-4'>
                    <ul>
                      <p className='mb-4'>Em todas as suas interações com o DIA, poderemos coletar seus dados:</p>
                      <li>Compras nos supermercados DIA</li>
                      <li>Uso do nosso site e suas funcionalidades (Newsletter, Imóveis, Canal de Privacidade de Dados)</li>
                      <li>Contato por meio do canal “Fale Conosco”</li>
                      <li>Interação em nossas redes sociais e nosso canal no YouTube</li>
                      <li>Comentários a nosso respeito no site Reclame Aqui</li>
                      <li>Cadastro e uso do Club Dia</li>
                      <li>Compras por meio do nosso canal de vendas “WhatsApp Dia”</li>
                      <li>Compras por meio de outras plataformas digitais</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-center pt-4 mt-5 pb-3'>
                <div className='col-auto position-relative'>
                  <img src={dado} className='position-absolute dado z-index-1' />
                </div>
                <div className='col-lg-4 col-12'>
                  <div className='bg-red-4 text-center text-white'>
                    <p className='font-weight-bold'>4. Quais dados pessoais o DIA coleta?</p>
                  </div>
                </div>
              </div>
              <div className='d-md-flex justify-content-center my-lg-5 pb-lg-5 pb-md-0 pb-5 mb-5'>
                <div className='col-lg-5 col-md-6 col-12 d-flex align-items-center position-relative'>
                  <img src={blackLine2} className='position-absolute blackLine2' />
                  <div className='text-center mx-lg-5 my-lg-0 my-5 z-index-1'>
                    <p className='mb-4'>Tudo vai depender da interação que você tem conosco. No geral, podemos ter acesso ao seu nome, seu e-mail, CPF, telefone, endereço e dados de log de acesso em nossos aplicativos (se você tiver o Club Dia, por exemplo).</p>
                    <p>Para saber em detalhes sobre quais dados coletamos em cada situação, veja a tabela ao final deste Aviso.</p>
                  </div>
                </div>
                <div className='d-lg-flex d-none justify-content-center align-items-center position-relative'>
                  <img src={centro} className='position-absolute centro' />
                </div>
                <div className='col-lg-5 col-md-6 col-12 ml-lg-0 ml-md-3 mt-md-0 mt-5 pt-md-0 pt-3 d-flex align-items-center position-relative'>
                  <img src={blackLine2} className='position-absolute blackLine2' />
                  <div className='text-center mx-lg-5 z-index-1'>
                    <p>Como regra, exceto pelas imagens capturadas no momento de sua visita em nossos estabelecimentos, não coletamos quaisquer outros Dados Pessoais Sensíveis. Caso haja algum outro motivo justificável (por exemplo, em caso de acidente de consumo, como alergias ou outras condições fiscais, poderemos solicitar Dados Pessoais Sensiveis. Informaremos antecipadamente a você.</p>
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-center pt-4 mt-5'>
                <div className='col-auto position-relative'>
                  <img src={biscoito} className='position-absolute biscoito z-index-1' />
                </div>
                <div className='col-lg-4 col-12'>
                  <div className='bg-red-5 text-center text-white'>
                    <p className='font-weight-bold'>5. Esperamos que você goste de cookies!</p>
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-center mt-3 mx-lg-4'>
                <div className='col-lg-10 col-12'>
                  <div className='bg-dark-water-blue-2 text-black px-lg-5 px-3 py-4 text-center'>
                    <p>Quando você visita nosso site ou nossos aplicativos, eles podem armazenar ou recuperar informações em seu navegador, seja na forma de cookies e de outras tecnologias semelhantes. Essas informações podem ser sobre você, suas preferências (de idioma, por exemplo) ou seu dispositivo, o tipo de navegador, o tempo que você utiliza nossos sites e aplicativos, as páginas visitadas, as preferências de idioma, e outros dados de tráfego anônimos. Você sempre poderá configurar os cookies nas nossas plataformas.</p>
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-center pt-4 mt-5'>
                <div className='col-auto position-relative'>
                  <img src={computador2} className='position-absolute dado z-index-1' />
                </div>
                <div className='col-lg-4 col-12'>
                  <div className='bg-red-4 text-center text-white'>
                    <p className='font-weight-bold'>6. Para que o DIA<br /> usa seus Dados Pessoais? </p>
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-center mt-3 mx-lg-4'>
                <div className='col-lg-7 col-12'>
                  <div className='bg-light-water-blue-2 px-lg-5 px-3 py-4'>
                    <ul>
                      <p className='mb-4'>Em resumo, o DIA trata seus dados pessoais</p>
                      <li>Para cumprir obrigações legais</li>
                      <li>Para cumprir o contrato celebrado com você</li>
                      <li>Para defender seus interesses em juízo ou fora dele</li>
                      <li>De acordo com o legítimo interesse e</li>
                      <li>Conforme o seu consentimento</li>
                      <p className='mt-4'>Para saber em detalhes sobre o tratamento dos seus dados pessoais, veja tabela ao final deste Aviso.</p>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-center pt-4 mt-5'>
                <div className='col-auto position-relative'>
                  <img src={mundo} className='position-absolute mundo z-index-1' />
                </div>
                <div className='col-lg-4 col-12'>
                  <div className='bg-red-7 text-center text-white'>
                    <p className='font-weight-bold'>7. Com quem o<br /> DIA compartilha<br /> meus dados pessoais?</p>
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-center mt-4 mx-lg-4'>
                <div className='col-lg-10 col-12 text-center position-relative'>
                  <img src={hotdog} className='position-absolute hotdog z-index-1' />
                  <div className='bg-dark-water-blue-3 text-black px-lg-5 px-3 py-lg-4 pb-4'>
                    <p className='font-weight-bold mb-4'>Nunca venderemos os seus dados pessoais.</p>
                    <p className='mb-4'>Somente compartilhamos seus dados com aqueles que tenham necessidade legítima para acessá-los. Sempre que o DIA permite que um terceiro acesse Dados Pessoais, o DIA se certifica de que os dados serão utilizados de maneira consistente com suas práticas de proteção de dados.</p>
                    <p>Os seus dados podem ser compartilhados com terceiros, como com provedores de serviços, outros parceiros comerciais e/ou autoridades competentes sempre que esse compartilhamento tiver uma razão para acontecer. Por exemplo, compartilharemos os seus dados com parceiros, que realizam os processos de antifraude (ex: Clearsale). O DIA também poderá compartilhar seus dados com terceiros em caso de qualquer reestruturação societária em que tal compartilhamento seja necessário.</p>
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-center pt-4 mt-5'>
                <div className='col-auto position-relative'>
                  <img src={ampulheta} className='position-absolute ampulheta z-index-1' />
                </div>
                <div className='col-lg-4 col-12'>
                  <div className='bg-red-8 text-center text-white'>
                    <p className='font-weight-bold'>8. Aqui no DIA seus dados são armazenados somente pelo prazo necessário </p>
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-center mt-md-3 mt-5 pt-lg-5 pb-5'>
                <div className='col-lg-10 col-12 d-flex justify-content-center align-items-center position-relative'>
                  <img src={blackLine3} className='w-90 position-absolute blackLine3 lightMode' />
                  <img src={blackLine3DM} className='w-90 position-absolute blackLine3 darkMode' />
                  <img src={blackLineMobile2} className='w-100 d-md-none position-absolute' />
                  <div className='text-center mx-lg-5 px-lg-5 z-index-1'>
                    <p>Os seus dados são armazenados somente pelo tempo que for necessário, ao menos que haja qualquer outra razão que justifique a manutenção por um período maior, como o cumprimento de obrigações legais, regulatórias, contratuais, defesa de nossos direitos em eventual processo judicial ou administrativo, etc.</p>
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-center pt-4 mt-5'>
                <div className='col-auto position-relative'>
                  <img src={nuvem} className='position-absolute nuvem z-index-1' />
                </div>
                <div className='col-lg-3 col-12'>
                  <div className='bg-red-9 text-center text-white'>
                    <p className='font-weight-bold'>9. Seus dados<br /> nas nuvens</p>
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-center mt-3 mx-lg-4'>
                <div className='col-lg-10 col-12 text-center'>
                  <div className='bg-light-water-blue-3 text-black px-lg-5 px-3 py-4'>
                    <p>Seus dados pessoais poderão ser transferidos para outros países tendo em vista o armazenamento dos dados em nuvem.  Ainda, em casos excepcionais, os dados pessoais poderão ser encaminhados a nossa matriz, localizada na Espanha. Quando transferirmos os seus dados para outro país, garantiremos que essa transferência obedeça às normas aplicáveis e seja conduzida preservando os seus direitos.</p>
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-center pt-5 mt-5'>
                <div className='col-auto position-relative'>
                  <img src={internet} className='position-absolute internet z-index-1' />
                </div>
                <div className='col-lg-3 col-12'>
                  <div className='bg-red-9 text-center text-white'>
                    <p className='font-weight-bold'>10. Seus dados, suas regras </p>
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-center mt-3 mx-lg-4'>
                <div className='col-lg-7 col-12'>
                  <div className='bg-dark-water-blue-4 text-black px-lg-5 px-3 py-4'>
                    <ul>
                      <p className='mb-1'>Os dados são seus. E, sendo assim, a LGPD garante alguns direitos, como:</p>
                      <li>Confirmar que seus dados estão sendo tratados</li>
                      <li>Acessar os dados coletados</li>
                      <li>Alterar dados incompletos, incorretos ou desatualizados</li>
                      <li>Pedir a anonimização (assim ninguém saberá que os dados são seus)</li>
                      <li>Pedir o bloqueio ou eliminação de dados conforme previsto na lei</li>
                      <li>Pedir a portabilidade dos dados</li>
                      <li>Ser informado sobre a possibilidade de não fornecer seu consentimento</li>
                      <li>Revogar o seu consentimento</li>
                      <li>Saber com quem compartilhamos os seus dados, etc.</li>
                      <p>Acesse o <a href='https://diasupermercados-privacy.my.onetrust.com/webform/e8ee1561-5f69-4f41-ab56-108e1791052c/8e8e6d7f-edff-42ab-9d22-631e5b3e4b77'>Canal de Privacidade de Dados do Dia Brasil</a> e saiba mais em nossa Política de Direito dos Titulares!</p>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-center pt-4 mt-5'>
                <div className='col-auto position-relative'>
                  <img src={cadeado} className='position-absolute cadeado z-index-1' />
                </div>
                <div className='col-lg-3 col-12'>
                  <div className='bg-red-11 text-center text-white'>
                    <p className='font-weight-bold'>11. Aqui, seus dados estão seguros</p>
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-center mt-3 mx-lg-4'>
                <div className='col-lg-10 col-12 text-center position-relative'>
                  <img src={hamburguer3} className='position-absolute hamburguer3 z-index-1' />
                  <div className='bg-light-water-blue-4 text-black px-lg-5 px-3 py-4'>
                    <p>Tomamos precauções razoáveis para proteger a confidencialidade, integridade e segurança de seus dados pessoais. Em caso de qualquer incidente que possa afetar os seus direitos, nós faremos uma comunicação formal a você.</p>
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-center pt-4 mt-5'>
                <div className='col-auto position-relative'>
                  <img src={passaro} className='position-absolute passaro z-index-1' />
                </div>
                <div className='col-lg-3 col-12'>
                  <div className='bg-red-11 text-center text-white'>
                    <p className='font-weight-bold'>12. Alguns avisos importantes</p>
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-center mt-3 mx-lg-4'>
                <div className='col-lg-7 col-12'>
                  <div className='bg-dark-water-blue-5 px-lg-5 px-3 py-4'>
                    <ul>
                      <li>Você é o único responsável pela veracidade, exatidão e autenticidade dos dados que fornece.</li>
                      <li>Não informe sua senha para ninguém!</li>
                      <li>O DIA jamais irá solicitar seus dados bancários por telefone. O único momento em que coletaremos dados bancários será para pagamentos de compras realizadas no Whatsapp Dia.</li>
                      <li>No nosso site, você também poderá encontrar links que te levarão para outras funcionalidades e, inclusive, sites e plataformas operados por terceiros: <b>Canal de Denúncias e Dia Carreiras</b>. As práticas de proteção de dados de tais canais constam de cada canal. Leia-as com atenção.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-center pt-4 mt-5'>
                <div className='col-auto position-relative'>
                  <img src={heroi} className='position-absolute heroi z-index-1' />
                </div>
                <div className='col-lg-3 col-12'>
                  <div className='bg-red-11 text-center text-white'>
                    <p className='font-weight-bold'>13. Encarregado de proteção de dados</p>
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-center mt-3 mx-lg-4'>
                <div className='col-lg-6 col-12 text-center'>
                  <div className='bg-light-water-blue-5 text-black px-lg-5 px-3 py-4'>
                    <p className='mb-4'>Encarregados: Tassia Siqueira e Fernando Romero</p>
                    <a href='mailto:dpo.brasil@diagroup.com' className='mb-4'>E-mail: dpo.brasil@diagroup.com</a><br /><br />
                    <a href='https://diasupermercados-privacy.my.onetrust.com/webform/e8ee1561-5f69-4f41-ab56-108e1791052c/8e8e6d7f-edff-42ab-9d22-631e5b3e4b77' className='font-weight-bold'>Canal de Privacidade de Dados do Dia Brasil</a>
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-center pt-5 mt-5'>
                <div className='col-auto position-relative'>
                  <img src={pessoa} className='position-absolute pessoa z-index-1' />
                </div>
                <div className='col-lg-4 col-12'>
                  <div className='bg-red-8 text-center text-white'>
                    <p className='font-weight-bold'>14. Dados Pessoais: Tratamentos e finalidades</p>
                  </div>
                </div>
              </div>
              <div className='my-5'>
                <table className='tablePrivacidade'>
                  <tr>
                    <th>CANAL</th>
                    <th>FONTE</th>
                    <th>DADO</th>
                    <th className='noBorderRight'>FINALIDADE</th>
                  </tr>
                  <tr>
                    <td>Supermercados Dia</td>
                    <td>Titular</td>
                    <td>Imagens (por exemplo face e placas de veículos)</td>
                    <td className='noBorderRight'>Monitoramento interno e externo por câmeras de segurança</td>
                  </tr>
                  <tr>
                    <td>Supermercados Dia</td>
                    <td>Coletados de forma automática no momento de sua visita em uma de nossas lojas</td>
                    <td>CPF, nome e endereço</td>
                    <td className='noBorderRight'>Para emitir nota fiscal; para realizar a entrega de produtos na sua casa</td>
                  </tr>
                  <tr>
                    <td>Site</td>
                    <td>Coletadas de forma automática</td>
                    <td>Coleta de informações sobre o navegador e o dispositivo que você está usando, seu endereço IP, sua localização, o endereço do site a partir do qual você chegou</td>
                    <td className='noBorderRight'>Para conduzir estudos analíticos sobre nosso Site e interações feita pelos usuários (desde que você tenha aceitados os cookies)</td>
                  </tr>
                  <tr>
                    <td>Site - Fale Conosco:</td>
                    <td>Titular</td>
                    <td>Nome, CPF, endereço, conteúdo de sua mensagem</td>
                    <td className='noBorderRight'>Entrar em contato com você e responder à sua dúvida, elogio ou reclamação.</td>
                  </tr>
                  <tr>
                    <td>Site - Imóveis:</td>
                    <td>Titular</td>
                    <td>Nome, telefone, e-mail</td>
                    <td className='noBorderRight'>Por meio do formulário “oportunidade em imóveis”, você poderá nos enviar seus dados de contato, para que possamos entrar em contato com você e entender mais sobre o imóvel que você tem para nos oferecer</td>
                  </tr>
                  <tr>
                    <td>Site - Newsletter:</td>
                    <td>Titular</td>
                    <td>E-mail</td>
                    <td className='noBorderRight'>Enviar ofertas e informações sobre o DIA. Você pode se inscrever em nossa newsletter e sair da nossa lista de e-mail a qualquer tempo.</td>
                  </tr>
                  <tr>
                    <td>Site - Canal de Proteção de Dados</td>
                    <td>Titular</td>
                    <td>Nome, e-mail, CPF (últimos dígitos), número de telefone e outros que você inserir na sua mensagem</td>
                    <td className='noBorderRight'>Para que possamos contatar você e responder à sua solicitação</td>
                  </tr>
                  <tr>
                    <td>Fale Conosco - telefone</td>
                    <td>Titular</td>
                    <td>Nome, endereço de e-mail, telefone, CPF e outros no conteúdo de sua mensagem</td>
                    <td className='noBorderRight'>Entrar em contato com você e responder à sua dúvida, elogio ou reclamação.</td>
                  </tr>
                  <tr>
                    <td>ReclameAqui<br /><br />
                      Atenção: o DIA e a Reclame
                      Aqui são empresas que
                      operam de forma
                      independente uma da outra.
                      Caso você tenha quaisquer
                      questões sobre o tratamento
                      de seus dados pelo
                      ReclameAqui, você deverá
                      entrar em contato
                      diretamente com o
                      ReclameAqui.
                    </td>
                    <td>ReclameAqui</td>
                    <td>Nome, CPF, data de nascimento, e-mail, telefone, data da reclamação, ID do site Reclame Aqui, localização (cidade e Estado), entre outros que forem enviadas por você ao ReclameAqui</td>
                    <td className='noBorderRight'>Entrar em contato com você e responder à sua reclamação.</td>
                  </tr>
                  <tr>
                    <td>
                      Redes Sociais<br /><br />
                      Atenção: o DIA e as Redes
                      Sociais são empresas que
                      operam de forma
                      independente uma da outra.
                      Caso você tenha quaisquer
                      questões sobre o tratamento
                      de seus dados por qualquer
                      das Redes Sociais, você
                      deverá entrar em contato
                      diretamente com elas.
                    </td>
                    <td>Titular</td>
                    <td>Nome e outros no conteúdo de sua mensagem</td>
                    <td className='noBorderRight'>Quando você envia mensagens, poderemos coletar o seu nome (conforme escrito na rede social) e a sua mensagem, especialmente para que possamos lhe responder. Nesse caso, direcionamos a sua solicitação ao nosso Fale Conosco.</td>
                  </tr>
                  <tr>
                    <td>Club Dia</td>
                    <td>Titular e de forma automática</td>
                    <td>IP, nome, e-mail, telefone, CPF, data de nascimento, telefone e CEP</td>
                    <td className='noBorderRight'>Para manutenção de logs de acesso, conduzir estudos analíticos acerca do uso do aplicativo e interações feita pelos usuários;
                      proporcionar atendimento aos usuários; para o envio de ofertas de produtos; para a oferta personalizada de produtos, com base em compras realizadas por você;
                      e para o envio de pesquisas de satisfação
                    </td>
                  </tr>
                  {/* <tr>
                    <td>Dia WhatsApp – Canal de vendas</td>
                    <td>Titular</td>
                    <td>Nome, endereço, telefone, CPF e informações de pagamento</td>
                    <td className='noBorderRight'>Para que possamos concluir a venda dos produtos e levarmos a mercadoria até você;
                      além disso, podemos conduzir a verificação de identidade e de outras atividades para prevenção de fraudes nas vendas e garantir sua proteção</td>
                  </tr> */}
                  {/* <tr>
                    <td>Dia WhatsApp – Ofertas:</td>
                    <td>Titular</td>
                    <td>Nome, telefone e e-mail</td>
                    <td className='noBorderRight'>Enviar ofertas a você.</td>
                  </tr> */}
                  {/* <tr className='noBorderBottom'>
                    <td>Plataformas de delivery (Uber,
                      Ifood, Rappi,Conershop)<br /><br />
                      Atenção: o DIA e as
                      plataformas de delivery são
                      empresas que operam de
                      forma independente uma da
                      outra. Caso você tenha
                      quaisquer questões sobre o
                      tratamento de seus dados
                      pelas plataformas de delivery,
                      ocê deverá entrar em contato
                      diretamente com elas.
                    </td>
                    <td>Plataformas de delivery</td>
                    <td>Nome, CPF, endereço de entrega</td>
                    <td className='noBorderRight'>Quando você realiza a compra de produtos em plataformas de delivery, essas plataformas nos enviam os seus dados para que possamos lhe enviar os produtos adquiridos e emitir nota fiscal.</td>
                  </tr> */}
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Privacidade
